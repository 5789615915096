import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useTrail, animated } from 'react-spring'
import Parallax from '../parallax'

import InView from '../in-view'
import MaskedImage from '../masked-image'
import Button from '../button'

export const fragment = graphql`
  fragment HomeServicesFragment on WordPress_Page_Homefields {
    services {
      title
      smallTitle
      imageOne {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageTwo {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
      buttonLabel
      serviceItem {
        title
      }
      serviceItem {
        title
      }
    }
  }
`

const HomeServices = ({
  title,
  smallTitle,
  imageOne,
  imageTwo,
  serviceItem,
  buttonLabel,
  buttonLink
}) => {
  const ref = useRef()
  const parallaxRef = useRef()
  const [animatedToggle, setAnimatedToggle] = useState(false)

  const listAnimation = useTrail(serviceItem.length, {
    opacity: animatedToggle ? 1 : 0,
    from: { opacity: 0 }
  })

  return (
    <Outer>
      <SmallTitle>{smallTitle}</SmallTitle>
      <ServicesBlock>
        <WrapImgOne>
          <MaskedImage fluid={imageOne.imageFile.childImageSharp.fluid} />
        </WrapImgOne>

        <HeadingWrapper>
          <Parallax speed="5">
            <h2>{title}</h2>
          </Parallax>
        </HeadingWrapper>

        <WrapItems>
          <InView element={ref.current} toggle={animatedToggle} setToggle={setAnimatedToggle}>
            <ul ref={ref}>
              {listAnimation.map(({ opacity }, index) => (
                <ServiceItem key={index} style={{ opacity }}>
                  {serviceItem[index].title}
                </ServiceItem>
              ))}
            </ul>
            <Button type="link" to={buttonLink.slug} text={buttonLabel} />
          </InView>
        </WrapItems>

        <WrapImgTwo>
          <MaskedImage fluid={imageTwo.imageFile.childImageSharp.fluid} />
        </WrapImgTwo>
      </ServicesBlock>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 0 0 0 16.5%;

  @media (max-width: 1260px) {
    padding: 0 12.5%;
  }

  @media (max-width: 650px) {
    padding: 0 0 0;
  }

  > .fade-wrapper {
    opacity: 1 !important;
  }
`

const SmallTitle = styled.p`
  display: block;
  display: flex;
  position: absolute;
  left: 5%;
  top: 65%;
  color: ${props => props.theme.colours.darkGrey};
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  transform: rotate(-90deg);

  @media (max-width: 800px) {
    display: none;
  }

  &:before {
    content: '';
    display: inline-block;
    background: ${props => props.theme.colours.orange};
    width: 1rem;
    height: 1rem;
    margin-right: 2rem;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(-50%);
  }

  @media (max-width: 350px) {
    transform: translateX(-60%);
  }
`

const ServicesBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    width: 100%;
  }

  h2 {
    width: 100%;
    font-family: ${props => props.theme.fonts.butler};
    font-size: 9rem;
    line-height: 0.95;
    color: ${props => props.theme.colours.orange};
    transform: rotate(-90deg) translate(35%, 35%);

    @media (max-width: 1400px) {
      font-size: 7rem;
    }

    @media (max-width: 767px) {
      font-size: 6.5rem;
    }
  }
`

const WrapImgOne = styled.div`
  position: relative;
  width: 40%;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-bottom: 120%;
  }

  @media (max-width: 767px) {
    width: 72.5%;
  }
`

const WrapImgTwo = styled.div`
  position: relative;
  width: 60%;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-bottom: 77%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const WrapItems = styled.div`
  width: 40%;
  padding: 10rem 0 0.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 10rem 8rem;
  }

  ul {
    margin-left: 0;
    margin-bottom: 5rem;
  }
`

const ServiceItem = styled(animated.li)`
  color: ${props => props.theme.colours.darkGrey};
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.5rem;
  text-transform: uppercase;
  list-style: none;
  letter-spacing: 0.2rem;
  margin-bottom: 2.5rem;
  transform: rotate(0);

  @media (max-width: 767px) {
    font-size: 1.8rem;
  }
`

export default HomeServices
