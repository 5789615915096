import React, { useState, useContext, useRef } from 'react'
import { graphql } from 'gatsby'
import { useTransition, useSpring, useChain, useTrail, animated, interpolate } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper } from 'swiper/js/swiper.esm'
import BezierEasing from 'bezier-easing'
import Parallax from '../parallax'
import { PreloaderContext } from '../context/preloader-context'

import LogoSvg from '../../images/logo.svg'
import ChevronLeftSvg from '../../images/chevron-left.svg'
import ChevronRightSvg from '../../images/chevron-right.svg'

import 'swiper/css/swiper.css'

export const fragment = graphql`
  fragment HomeHeroFragment on WordPress_Page_Homefields {
    hero {
      carouselText
      imageCarousel {
        slideText
        imageBack {
          focalPoint
          mobileFocalPoint
          image {
            altText
            sourceUrl
            mediaItemId
            modified
            id
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        imageFront {
          focalPoint
          mobileFocalPoint
          image {
            altText
            sourceUrl
            mediaItemId
            modified
            id
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const Hero = ({ imageCarousel, carouselText }) => {
  const [frontSwiper, getFrontSwiper] = useState(null)
  const [backSwiper, getBackSwiper] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const [preloaderStatus] = useContext(PreloaderContext)

  const slideTextTransitions = useTransition(imageCarousel[slideIndex], item => item.slideText, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
    config: { duration: 2000 }
  })

  const goNext = () => {
    if (frontSwiper !== null) {
      backSwiper.slidePrev()
      setTimeout(() => {
        frontSwiper.slideNext()
      }, 500)
    }

    setSlideIndex((slideIndex + 1) % imageCarousel.length)
  }

  const goPrev = () => {
    if (frontSwiper !== null) {
      backSwiper.slideNext()
      setTimeout(() => {
        frontSwiper.slidePrev()
      }, 1000)
    }

    setSlideIndex((slideIndex + (imageCarousel.length - 1)) % imageCarousel.length)
  }

  // Amount to overlap slides
  const interleaveOffset = 0.9
  const swiperConfig = {
    Swiper,
    slidesPerView: 1,
    speed: 1200,
    loopAdditionalSlides: 3,
    watchSlidesProgress: true,
    roundLengths: true,
    loop: true,
    on: {
      progress() {
        const swiper = this
        for (let i = 0; i < swiper.slides.length; i += 1) {
          const slideProgress = swiper.slides[i].progress
          const innerOffset = swiper.width * interleaveOffset
          const innerTranslate = slideProgress * innerOffset
          swiper.slides[i].querySelector(
            '.front-swiper-image'
          ).style.transform = `translate3d(${innerTranslate}px,0, 0)`
        }
      },
      touchStart() {
        const swiper = this
        for (let i = 0; i < swiper.slides.length; i += 1) {
          swiper.slides[i].style.transition = ''
        }
      },
      setTransition(speed) {
        const swiper = this
        for (let i = 0; i < swiper.slides.length; i += 1) {
          swiper.slides[i].style.transition = `${speed}ms`
          swiper.slides[i].querySelector(
            '.front-swiper-image'
          ).style.transition = `${speed}ms cubic-bezier(0.77, 0, 0.175, 1)`
        }
      }
    }
  }

  const frontSwiperParams = {
    getSwiper: getFrontSwiper,
    ...swiperConfig
  }

  const backSwiperParams = {
    getSwiper: getBackSwiper,
    ...swiperConfig
  }

  // Because the front slider need to run in reverse order
  // we need a new "massaged" array
  const imageCarouselReversed = imageCarousel.slice().reverse()
  const imageCarouselReversedLastItem = [imageCarouselReversed[imageCarouselReversed.length - 1]]
  const imageCarouselReversedWithoutLast = imageCarouselReversed.splice(
    0,
    imageCarouselReversed.length - 1
  )
  const imageCarouselReversedReorder = imageCarouselReversedLastItem.concat(
    imageCarouselReversedWithoutLast
  )

  const backImageIntroAnimationRef = useRef()
  const backImageIntroAnimation = useSpring({
    ref: backImageIntroAnimationRef,
    from: {
      opacity: 0,
      transform: 'translate3d(-10%,0,0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)'
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const FrontImageOuterAnimationRef = useRef()
  const FrontImageOuterAnimation = useSpring({
    ref: FrontImageOuterAnimationRef,
    from: {
      transform: `translate3d(101%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const FrontImageInnerAnimationRef = useRef()
  const FrontImageInnerAnimation = useSpring({
    ref: FrontImageInnerAnimationRef,
    from: {
      transform: `translate3d(-90%, 0, 0)`
    },
    to: {
      transform: `translate3d(0%, 0, 0)`
    },
    config: {
      duration: 1200,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  const logoAnimationRef = useRef()
  const logoAnimation = useSpring({
    ref: logoAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  const slideTextAnimationRef = useRef()
  const slideTextAnimation = useSpring({
    ref: slideTextAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  const arrowTextAnimationRef = useRef()
  const arrowTextAnimation = useSpring({
    ref: arrowTextAnimationRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  const lineAnimationRef = useRef()
  const lineAnimation = useSpring({
    ref: lineAnimationRef,
    from: { transform: 'scaleX(0)' },
    to: { transform: 'scaleX(1)' },
    config: {
      duration: 500,
      easing: BezierEasing(0.77, 0, 0.175, 1)
    }
  })

  // Split the text string into an array of words
  const splitText = carouselText.split(' ')

  const wordAnimationRef = useRef()
  const wordAnimation = useTrail(splitText.length, {
    ref: wordAnimationRef,
    to: {
      y: 0,
      rotate: 0,
      opacity: 1
    },
    from: { rotate: 5, y: 30, opacity: 0 },
    config: {
      mass: 10,
      tension: 1000,
      friction: 200
    }
  })

  useChain(
    !preloaderStatus.shouldPlay && preloaderStatus.finished
      ? [
          backImageIntroAnimationRef,
          FrontImageOuterAnimationRef,
          FrontImageInnerAnimationRef,
          wordAnimationRef,
          slideTextAnimationRef,
          lineAnimationRef,
          logoAnimationRef,
          arrowTextAnimationRef
        ]
      : [],
    [0, 0.5, 0.5, 1, 1, 1.5, 2, 3]
  )

  const WipeAnimation = useSpring({
    from: { transform: 'translate3d(0%, 0, 0)' },
    to: { transform: 'translate3d(100%, 0, 0)' },
    config: {
      duration: 1200,
      easing: BezierEasing(0.785, 0.135, 0.15, 0.86)
    }
  })

  return (
    <Outer>
      <Wipe style={WipeAnimation} />
      <Inner>
        <Logo style={logoAnimation} mobile={false}>
          <LogoSvg />
        </Logo>
        <Heading>
          <Parallax speed={4}>
            <Logo style={logoAnimation} mobile>
              <LogoSvg />
            </Logo>
            <animated.h1>
              {wordAnimation.map(({ rotate, y, ...rest }, index) => (
                <Word
                  key={[index]}
                  style={{
                    ...rest,
                    transform: interpolate(
                      [y, rotate],
                      (y, rotate) => `translateY(${y}px) rotate(${rotate}deg)`
                    )
                  }}
                >
                  {splitText[index]}&nbsp;
                </Word>
              ))}
              <Line style={lineAnimation} />
            </animated.h1>
          </Parallax>
        </Heading>
        <Arrows style={arrowTextAnimation}>
          {imageCarousel.length > 1 && (
            <>
              <ArrowLeft onClick={() => goPrev()}>
                <ChevronLeftSvg />
              </ArrowLeft>
              <ArrowRight onClick={() => goNext()}>
                <ChevronRightSvg />
              </ArrowRight>
            </>
          )}
        </Arrows>
        <SlideText>
          {slideTextTransitions.map(({ item, props, key }) => (
            <animated.p key={key} style={{ ...props }}>
              {/* <animated.span style={slideTextAnimation}>{item.slideText}</animated.span> */}
            </animated.p>
          ))}
        </SlideText>
        <ImageFrontContainer>
          <ImageFrontOuter style={FrontImageOuterAnimation}>
            <ImageFrontInner style={FrontImageInnerAnimation}>
              <Parallax speed={10} position="fill">
                <ImageFront>
                  <ReactIdSwiperCustom {...frontSwiperParams}>
                    {imageCarousel.map((image, i) => (
                      <div key={i}>
                        <GatsbyImage
                          className="front-swiper-image"
                          key={i}
                          fluid={image.imageFront.image.imageFile.childImageSharp.fluid}
                          alt={image.imageFront.image.alt}
                          loading="eager"
                        />
                      </div>
                    ))}
                  </ReactIdSwiperCustom>
                </ImageFront>
              </Parallax>
            </ImageFrontInner>
          </ImageFrontOuter>
        </ImageFrontContainer>
      </Inner>
      <ImageBackContainer style={backImageIntroAnimation}>
        <ImageBack>
          <ReactIdSwiperCustom {...backSwiperParams}>
            {imageCarouselReversedReorder.map((image, i) => (
              <div key={i}>
                <GatsbyImage
                  className="front-swiper-image"
                  key={i}
                  fluid={image.imageBack.image.imageFile.childImageSharp.fluid}
                  alt={image.imageBack.image.alt}
                  loading="eager"
                />
              </div>
            ))}
          </ReactIdSwiperCustom>
        </ImageBack>
      </ImageBackContainer>
    </Outer>
  )
}

export default Hero

const Outer = styled.section`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 1200px) {
    height: 100vh;
  }
`

const Inner = styled.section`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 67%;
  z-index: 2;

  @media (max-width: 1260px) {
    width: 85%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
  }
`

const Logo = styled(animated.div)`
  display: ${props => (props.mobile ? 'none' : 'block')};
  position: absolute;
  top: 20%;
  left: 0;
  transform: translateX(-30%);
  z-index: 10;

  @media (max-width: 1260px) {
    transform: translateX(0);
  }

  @media (max-width: 800px) {
    display: ${props => (props.mobile ? 'block' : 'none')};
    top: -120%;
  }

  > div {
    @media (max-width: 800px) {
      transform: translate3d(0, 0, 0) !important;
    }
  }

  svg {
    width: 30rem;

    @media (max-width: 1000px) {
      width: 35rem;
    }

    @media (max-width: 650px) {
      width: 260px;
    }
  }
`

const Heading = styled.div`
  position: relative;
  font-size: 10rem;
  width: 100%;
  max-width: 70rem;
  color: #fff;
  word-wrap: normal;
  z-index: 10;

  @media (max-width: 800px) {
    width: 100%;
    height: 50%;
  }

  h1 {
    font-size: 10rem;
    letter-spacing: -0.2rem;

    @media (max-width: 800px) {
      margin-top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 650px) {
      font-size: 7.8rem;
      margin-top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 380px) {
      font-size: 7rem;
      width: 100%;
    }
  }
`

const Line = styled(animated.div)`
  position: absolute;
  bottom: -30%;
  left: 0;
  width: 5.7rem;
  height: 1px;
  background-color: #fff;
  transform-origin: left;

  @media (max-width: 800px) {
    bottom: -5%;
  }
`

const SlideText = styled.div`
  position: absolute;
  top: 5vh;
  right: -1.8rem;
  transform: translateX(calc(100% + 1.8rem)) rotate(90deg);
  transform-origin: left top;
  z-index: 2;

  @media (max-width: 800px) {
    top: initial;
    right: 8%;
    bottom: 2.5%;
    width: 100%;
    text-align: right;
    transform: translateX(0) rotate(0);
    height: 42px;
    overflow: hidden;
    z-index: 10;
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    line-height: 3;
    text-transform: uppercase;

    @media (max-width: 800px) {
      font-size: 2rem;
    }

    color: #fff;
  }
`

const ImageBackContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  pointer-events: none;
  will-change: transform;
  z-index: 1;

  @media (max-width: 800px) {
    display: none;
  }
`

const ImageBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 5;
  }

  .swiper-wrapper {
    height: 90vh;
    will-change: transform;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .swiper-slide,
  .swiper-slide-duplicate {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`

const GatsbyImage = styled(Img)`
  width: 100%;
  height: 100%;
  will-change: transform;

  img {
    opacity: 1 !important;
  }
`

const ImageFrontContainer = styled.section`
  position: absolute;
  bottom: 5vh;
  right: 0;
  width: 56.5%;
  height: 90vh;
  overflow: hidden;
  pointer-events: none;
  transform-origin: right;
  overflow: hidden;
  z-index: 5;

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    bottom: 0;
  }
`

const ImageFrontOuter = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  overflow: hidden;

  > div {
    @media (max-width: 800px) {
      transform: translate3d(0, 0, 0) !important;
    }
  }
`

const ImageFrontInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
`

const ImageFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;

  @media (max-width: 1200px) {
    height: 95vh;
  }

  @media (max-width: 800px) {
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 95vh;
      z-index: 5;
    }
  }

  .swiper-wrapper {
    height: 90vh;
    will-change: transform;
    transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1);

    @media (max-width: 1200px) {
      height: 95vh;
    }
  }

  .swiper-slide,
  .swiper-slide-duplicate {
    overflow: hidden;
    width: 100%;
    height: 100%;

    @media (max-width: 800px) {
      opacity: 0.35;
    }
  }
`

const Arrows = styled(animated.nav)`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 11.5%;
  right: -1.8rem;
  transform: translateX(100%);
  z-index: 20;

  @media (max-width: 800px) {
    bottom: 10%;
    right: 5%;
    transform: translateX(0);
  }

  svg {
    width: 12px;
    height: 23px;
    fill: #fff;

    @media (max-width: 800px) {
      width: 18px;
      height: 33px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1.2rem;

    @media (max-width: 800px) {
      padding: 1.2rem 2rem;
    }
  }
`

const ArrowLeft = styled.div``

const ArrowRight = styled.div``

const Word = styled(animated.span)`
  display: inline-block;
  transform-origin: left bottom;
  opacity: 0;
  will-change: transform;
`

const Wipe = styled(animated.aside)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: ${props => props.theme.colours.darkGrey};
  z-index: 50;
`
