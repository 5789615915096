import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HomeHero from '../components/home/hero'
import HomeIntro from '../components/home/intro'
import HomeServices from '../components/home/services'
import LatestProjects from '../components/home/latest-projects'
import Seo from '../components/seo'

const Home = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.pageBy.seo} />
    <HomeHero {...data.wordPress.pageBy.homeFields.hero} />
    <HomeIntro {...data.wordPress.pageBy.homeFields.intro} />
    <HomeServices {...data.wordPress.pageBy.homeFields.services} />
    <LatestProjects projects={data.wordPress.pageBy.homeFields.latestProjects} />
  </Layout>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        homeFields {
          ...HomeHeroFragment
          ...HomeIntroFragment
          ...HomeServicesFragment
          ...HomeLatestProjectsFragment
        }
      }
    }
  }
`
