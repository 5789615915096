import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper } from 'swiper/js/swiper.esm'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { useSpring, useChain, useTransition, animated, config } from 'react-spring'
import InView from '../in-view'

import Button from '../button'
import ChevronLeftSvg from '../../images/chevron-left.svg'
import ChevronRightSvg from '../../images/chevron-right.svg'

import 'swiper/css/swiper.css'

export const fragment = graphql`
  fragment HomeLatestProjectsFragment on WordPress_Page_Homefields {
    latestProjects {
      ... on WordPress_Project {
        slug
        title
        projectFields {
          hero {
            address
            image {
              altText
              sourceUrl
              mediaItemId
              modified
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ImageCarousel = ({ projects }) => {
  const ref = useRef()
  const [toggle, setToggle] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [swiper, updateSwiper] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)

  const titleAnimationRef = useRef()
  const titleAnimation = useSpring({
    ref: titleAnimationRef,
    from: { transform: 'rotate(5deg)', opacity: 0 },
    to: { transform: 'rotate(0deg)', opacity: 1 },
    config: {
      mass: 5,
      tension: 1200,
      friction: 200
    }
  })

  const maskAnimationRef = useRef()
  const maskAnimation = useSpring({
    ref: maskAnimationRef,
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: `translate3d(0, 0%, 0)`
    },
    config: config.slow
  })

  const maskAnimationInnerRef = useRef()
  const maskAnimationInner = useSpring({
    ref: maskAnimationInnerRef,
    from: {
      transform: `translate3d(0, -101%, 0)`
    },
    to: {
      transform: `translate3d(0, 0%, 0)`
    },
    config: config.slow
  })

  const projectTitleTransition = useTransition(projects[currentIndex], item => item.title, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useChain(toggle ? [maskAnimationRef, maskAnimationInnerRef, titleAnimationRef] : [], [0, 0, 0.5])

  const params = {
    Swiper,
    slidesPerView: 'auto',
    loop: projects.length > 1,
    centeredSlides: true,
    speed: 1000,
    threshold: projects.length > 1 ? 20 : 9999,
    spaceBetween: 30,
    loopedSlides: projects.length > 1 ? 5 : 0
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChangeTransitionEnd', () => setCurrentIndex(swiper.realIndex))
    }
  }, [swiper])

  return (
    <Outer ref={ref}>
      <InView element={ref.current} toggle={toggle} setToggle={setToggle} />
      {projects.length > 1 && (
        <SwiperOverlayArrows>
          <SwiperOverlayArrowsInner>
            <SwiperNext onClick={() => goPrev()} />
            <SwiperPrev onClick={() => goNext()} />
          </SwiperOverlayArrowsInner>
        </SwiperOverlayArrows>
      )}
      <Inner>
        <Title style={titleAnimation}>In development</Title>
        <ProjectTitle>
          {projectTitleTransition.map(({ item, key, props }) => (
            <animated.span key={key} style={{ ...props }}>
              {item.title}
              <br />
              {item.projectFields.hero.address}
            </animated.span>
          ))}
        </ProjectTitle>
        <ButtonWrapper>
          <Button type="link" text="View project" to={`projects/${projects[currentIndex].slug}/`} />
        </ButtonWrapper>
        {projects.length > 1 && (
          <Arrows>
            <ArrowLeft onClick={() => goNext()}>
              <ChevronLeftSvg />
            </ArrowLeft>
            <ArrowRight onClick={() => goPrev()}>
              <ChevronRightSvg />
            </ArrowRight>
          </Arrows>
        )}
      </Inner>
      <CarouselWrapper>
        <CarouselOuter style={maskAnimation}>
          <CarouselInner style={maskAnimationInner}>
            <ReactIdSwiperCustom getSwiper={updateSwiper} {...params}>
              {projects.map((project, index) => (
                <div key={index}>
                  <ImageContainer>
                    <Image
                      fluid={project.projectFields.hero.image.imageFile.childImageSharp.fluid}
                      alt={project.projectFields.hero.image.altText}
                      backgroundColor="#242424"
                      loading="eager"
                    />
                  </ImageContainer>
                </div>
              ))}
            </ReactIdSwiperCustom>
          </CarouselInner>
        </CarouselOuter>
      </CarouselWrapper>
    </Outer>
  )
}

export default ImageCarousel

const Outer = styled.div`
  position: relative;
  display: flex;
  margin: 15vw 0;

  @media (max-width: 650px) {
    margin-top: 22vw;
  }

  .swiper-container {
    width: 100%;

    img {
      opacity: 1 !important;
    }
  }

  .swiper-slide {
    width: 65%;
    /* transform: translateX(calc(50% + 30px)) !important; */
    transition: opacity 0.6s ease;
    background-color: ${props => props.theme.colours.darkGrey};

    @media (max-width: 767px) {
      width: calc(100% - 118px);
    }

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next,
  .swiper-slide-duplicate-prev,
  .swiper-slide-duplicate-next {
    opacity: 0.2;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    will-change: transform;
  }
`

const Inner = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0;
  width: calc(40% + 30px);
  padding-left: 7.5rem;
  height: 100%;
  margin: 0 auto;
  background-color: var(--secondary);
  transition: background 0.8s linear;
  z-index: 5;

  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    padding: 0 0 0 8rem;
    background-color: transparent;
    z-index: 1;
  }
`

const CarouselWrapper = styled.div`
  position: relative;
  width: 70%;
  overflow: hidden;

  @media (max-width: 767px) {
    position: absolute;
    width: 100%;
    top: 14rem;
    left: 0;
    z-index: 5;
  }
`

const CarouselOuter = styled(animated.div)`
  position: relative;
  top: 0;
  left: 0%;
  width: 100%;
  overflow: hidden;
`

const CarouselInner = styled(animated.div)`
  position: relative;
  top: 0;
  left: 0%;
  width: 100%;
`

const Title = styled(animated.h2)`
  position: absolute;
  top: 20%;
  left: -29%;
  font-size: 9.5rem;
  color: ${props => props.theme.colours.orange};
  transform-origin: top left;
  white-space: nowrap;
  pointer-events: none;
  letter-spacing: -0.2rem;
  z-index: 5;

  @media (max-width: 1450px) {
    font-size: 7.5rem;
  }

  @media (max-width: 1150px) {
    font-size: 6.5rem;
  }

  @media (max-width: 767px) {
    position: relative;
    font-size: 6.5rem;
    top: initial;
    left: initial;
    margin-bottom: 6rem;
  }

  @media (max-width: 400px) {
    font-size: 6rem;
  }

  @media (max-width: 350px) {
    font-size: 5rem;
  }
`

const ProjectTitle = styled.p`
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  color: ${props => props.theme.colours.darkGrey};
  line-height: 2;
  margin: 8vw 0 4vw;

  @media (max-width: 767px) {
    font-size: 1.8rem;
    margin: 90vw 0 4vw;
  }
`

const ImageContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Arrows = styled(animated.nav)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 12%;
  left: 0;
  transform: translateX(100%);
  z-index: 20;

  @media (max-width: 1450px) {
    top: 8%;
  }

  @media (max-width: 1000px) {
    top: 5%;
  }

  @media (max-width: 767px) {
    top: 8%;
    left: -1.2rem;
  }

  svg {
    width: 12px;
    height: 23px;
    fill: ${props => props.theme.colours.darkGrey};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1.2rem;
  }
`

const ArrowLeft = styled.div``

const ArrowRight = styled.div``

const SwiperOverlayArrows = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;

  @media (max-width: 1000px) {
    pointer-events: none;
  }
`

const SwiperOverlayArrowsInner = styled.nav`
  position: absolute;
  top: 0;
  right: 30px;
  width: 65%;
  height: 100%;

  @media (max-width: 767px) {
    display: none;
    pointer-events: none;
  }
`

const SwiperArrow = styled.figure`
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 5;
`

const SwiperNext = styled(SwiperArrow)`
  right: 0;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHdpZHRoPSIxMDBweCIgYmFzZVByb2ZpbGU9InRpbnkiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgoJIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTI3LjkgNjEuMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNENTY1MUIiIGQ9Ik0wLDMyLjloMTE4LjZjLTguMyw4LjMtMTYuNCwxNi41LTI0LjUsMjQuNmMxLjIsMS4yLDIuNCwyLjMsMy42LDMuNWMwLjEtMC4xLDAuMy0wLjMsMC40LTAuNQoJYzkuOC05LjgsMTkuNS0xOS41LDI5LjMtMjkuM2MwLjItMC4yLDAuNC0wLjMsMC42LTAuNXYtMC4xYy0xMC0xMC0yMC4xLTIwLjEtMzAuMS0zMC4xYy0wLjEtMC4xLTAuMi0wLjMtMC4zLTAuNGgtMC4xCgljLTEuMSwxLjItMi4zLDIuNC0zLjYsMy43YzguMiw4LjIsMTYuNCwxNi40LDI0LjUsMjQuNUgwVjMyLjl6Ii8+Cjwvc3ZnPgo=')
      100 0,
    pointer;
`

const SwiperPrev = styled(SwiperArrow)`
  left: 0%;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHdpZHRoPSIxMDBweCIgYmFzZVByb2ZpbGU9InRpbnkiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgoJIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTI3LjkgNjEuMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNENTY1MUIiIGQ9Ik0xMjgsMjguMUg5LjRjOC4zLTguMywxNi40LTE2LjUsMjQuNS0yNC42Yy0xLjItMS4yLTIuNC0yLjMtMy42LTMuNWMtMC4xLDAuMS0wLjMsMC4zLTAuNCwwLjUKCWMtOS44LDkuOC0xOS41LDE5LjUtMjkuMywyOS4zQzAuNCwzMCwwLjIsMzAuMSwwLDMwLjN2MC4xYzEwLDEwLDIwLjEsMjAuMSwzMC4xLDMwLjFjMC4xLDAuMSwwLjIsMC4zLDAuMywwLjRoMC4xCgljMS4xLTEuMiwyLjMtMi40LDMuNi0zLjdDMjUuOSw0OS4xLDE3LjgsNDEsOS42LDMyLjhIMTI4VjI4LjF6Ii8+Cjwvc3ZnPgo=')
      100 0,
    pointer;
`

const ButtonWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: 10rem;
  }
`
